import React, { useState, useEffect } from 'react';
import { FaPlus, FaRegTrashAlt, FaPencilAlt, FaBook, FaRegFileArchive } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Input, message, Popconfirm, Tag, Select } from 'antd';
// import { Select } from 'formik-antd';
import { useHistory } from 'react-router-dom';

import { decrypt, getColorByAbstractStatus } from '~/Utils';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import { TableHeader, Table, TableActions } from '~/components/Table';
import Box from '~/components/Box';
import Pagination from '~/components/Pagination';
import DefaultLayout from '../_layouts/full';

export default function Abstract() {
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(false);
  const [subareaOptions, setSubareaOptions] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchBySubarea, setSearchBySubarea] = useState(null);

  const { t } = useTranslation();
  const history = useHistory();

  const person =
    JSON.parse(decrypt(localStorage.getItem('@Portal:person'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@Portal:person')));

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await api.get('/resumes', {
        params: {
          search: searchTerm || null,
          subarea_id: searchBySubarea || 0,
        },
      });

      setMeta(response.data.meta);
      setRecordData(response.data.data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleNew = () => {
    history.push('/resumos/novo');
  };

  const handleEdit = async id => {
    history.push(`/resumos/${id}/edicao`);
  };

  const handleAssign = async id => {
    setLoading(true);
    try {
      await api.put(`resumes/assign/${id}`);
      message.success(t('messages:successAssigned'));
      await handleSearch();
      handleEdit(id);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleDelete = async id => {
    setLoading(true);
    try {
      await api.delete(`resumes/${id}`);
      message.success(t('messages:success'));
      await handleSearch();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangePage = async value => {
    setLoading(true);
    try {
      const response = await api.get(`resumes/?page=${value}`, {
        params: {
          search: searchTerm || null,
          subarea_id: searchBySubarea || 0,
        },
      });

      setRecordData(response.data.data);
      setMeta(response.data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchSubareaOptions = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/resumes/available/subareas');
      console.log(data);
      setSubareaOptions(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSubareaOptions();
  }, []);

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchBySubarea]);

  // const handleDownloadCertificate = record => {
  //   api
  //     .post(`subscriptions/resume/certificate/${record.id}`)
  //     .then(response => {
  //       // Abre o PDF em uma nova guia do navegador
  //       window.open(response.data, '_blank');
  //     })
  //     .catch(error => {
  //       // Lógica para lidar com erros durante o POST
  //       console.error('Erro ao baixar o certificado:', error);
  //       // Aqui você pode adicionar lógica para lidar com erros, como exibir uma mensagem de erro
  //     });
  // };

  const tableColumns = [
    // {
    //   title: t('screens:abstracts.data.title'),
    //   dataIndex: 'title',
    //   key: 'title',
    //   responsive: ['sm', 'md', 'lg'],
    //   ellipsis: true,
    // },
    {
      title: t('screens:abstracts.data.title'),
      key: 'congress.description',
      width: '45%',
      // ellipsis: true,
      responsive: ['sm', 'md', 'lg'],
      render: (text, record) => (
        <>
          {record.title && <p style={{ margin: 0, padding: 0 }}>{record.title}</p>}
          {record.congress && record.congress.description && (
            <p style={{ fontSize: 'small', margin: 0, padding: 0, color: 'gray' }}>({record.congress.description})</p>
          )}
        </>
      ),
    },
    {
      title: t('screens:abstracts.data.subarea'),
      key: 'subArea.name',
      ellipsis: true,
      responsive: ['sm', 'md', 'lg'],
      render: (text, record) => record.subArea && record.subArea.name,
    },
    {
      title: t('screens:abstracts.data.updated_at'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      responsive: ['sm', 'md', 'lg'],
      width: 200,
      render: text => new Date(text).toLocaleString(),
    },
    {
      title: t('screens:abstracts.data.words'),
      dataIndex: 'word',
      key: 'word',
      ellipsis: true,
      responsive: ['md', 'lg'],
      render: (text, record) => {
        let words;
        if (record.word_1) {
          words = `${record.word_1}; `;
        }
        if (record.word_2) {
          words += `${record.word_2}; `;
        }
        if (record.word_3) {
          words += `${record.word_3}; `;
        }
        if (record.word_4) {
          words += `${record.word_4}; `;
        }
        if (record.word_5) {
          words += `${record.word_5}; `;
        }
        return words;
      },
    },
    {
      title: t('screens:abstracts.data.status'),
      dataIndex: 'status',
      key: 'status',
      width: 120,
      responsive: ['sm', 'md', 'lg'],
      render: (text, record) => (
        <Tag color={getColorByAbstractStatus(record.status, person.is_evaluator)}>{record.status}</Tag>
      ),
    },
    {
      title: t('screens:abstracts.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      align: 'center',
      responsive: ['sm', 'md', 'lg'],
      render: (text, record) => (
        <TableActions>
          {person.is_evaluator && !record.evaluator_id && (
            <Popconfirm
              title={t('messages:confirmAssign')}
              okText={t('messages:yes')}
              onConfirm={() => handleAssign(record.id)}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Atribuir">
                <FaBook />
              </Button>
            </Popconfirm>
          )}
          {((person.is_evaluator && record.status === 'Pendente' && record.evaluator_id === person.id) ||
            (!person.is_evaluator && record.status === 'Aguardando correção')) && (
            <Button size="small" title="Editar" onClick={() => handleEdit(record.id)}>
              <FaPencilAlt />
            </Button>
          )}
          {!person.is_evaluator && record.person.id === person.id && (
            <Popconfirm
              title={t('messages:confirmDelete')}
              okText={t('messages:yes')}
              onConfirm={() => handleDelete(record.id)}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
          )}
          {!person.is_evaluator && record.person.id === person.id && record.status === 'Aprovado' && (
            <Button
              size="small"
              title="Baixar Certificado"
              // onClick={() => handleDownloadCertificate(record)}
              style={{ cursor: 'not-allowed' }}
            >
              <FaRegFileArchive />
            </Button>
          )}
        </TableActions>
      ),
    },
    {
      title: 'Dados',
      key: 'all',
      responsive: ['xs'],
      width: '100%',
      // ellipsis: true,
      render: (text, record) => (
        <>
          <p style={{ fontWeight: 'bold' }}>{record.congress && record.congress.description}</p>
          <p>{record.subArea && record.subArea.name}</p>
          <p>{new Date(record.updated_at).toLocaleString()}</p>
          <TableActions>
            {person.is_evaluator && !record.evaluator_id && (
              <Popconfirm
                title={t('messages:confirmAssign')}
                okText={t('messages:yes')}
                onConfirm={() => handleAssign(record.id)}
                cancelText={t('messages:no')}
              >
                <Button size="small" title="Atribuir">
                  <FaBook />
                </Button>
              </Popconfirm>
            )}
            {((person.is_evaluator && record.status === 'Pendente' && record.evaluator_id === person.id) ||
              (!person.is_evaluator && record.status === 'Aguardando correção')) && (
              <Button size="small" title="Editar" onClick={() => handleEdit(record.id)}>
                <FaPencilAlt /> Editar
              </Button>
            )}
            {!person.is_evaluator && record.person.id === person.id && (
              <Popconfirm
                title={t('messages:confirmDelete')}
                okText={t('messages:yes')}
                onConfirm={() => handleDelete(record.id)}
                cancelText={t('messages:no')}
              >
                <Button size="small" title="Excluir">
                  <FaRegTrashAlt /> Excluir
                </Button>
              </Popconfirm>
            )}
            {!person.is_evaluator && record.person.id === person.id && record.status === 'Aprovado' && (
              <Button
                size="small"
                title="Baixar Certificado"
                // onClick={() => handleDownloadCertificate(record)}
                style={{ cursor: 'not-allowed' }}
              >
                <FaRegFileArchive /> Baixar Certificado
              </Button>
            )}
          </TableActions>
        </>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:abstracts.title')}>
        {!person.is_evaluator && (
          <Button loading={loading} color="primary" onClick={handleNew}>
            <FaPlus />
            {t('screens:abstracts.btnNew')}
          </Button>
        )}
      </PageTitle>
      <Box>
        <TableHeader>
          <Select
            placeholder={t('screens:abstracts.data.subarea')}
            style={{ minWidth: 150, marginRight: 10 }}
            name="subarea_id"
            onChange={id => setSearchBySubarea(id)}
            allowClear
            optionFilterProp="children"
          >
            {subareaOptions &&
              subareaOptions.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
          <Input.Search onSearch={value => setSearchTerm(value)} className="search-field" />
        </TableHeader>
        <Table
          pagination={false}
          rowKey="id"
          loading={loading}
          dataSource={recordData}
          columns={tableColumns}
          // scroll={{ x: 800 }}
        />
        <Pagination
          onChange={value => {
            handleChangePage(value);
          }}
          meta={meta}
        />
      </Box>
    </DefaultLayout>
  );
}
